import React, { useEffect, useState } from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SignUp from "../sections/signup"

function SignupPage({ location }) {
  const [trackingUtm, setTrackingUtm] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const body = {
      origin: 'LP - Lider',
      utm_source: params.get('utm_source') || null,
      utm_medium: params.get('utm_medium') || null,
      utm_campaign: params.get('utm_campaign') || null,
      utm_term: params.get('utm_term') || null,
      utm_content: params.get('utm_content') || null,
    }

    if(location.state?.trackingUtm){
      setTrackingUtm(location.state?.trackingUtm);
    }else{
      setTrackingUtm(body);
      localStorage.setItem('tracking', JSON.stringify(body));
    }
  }, []);

  return (
    <Layout>
      <SEO title="Cadastro" />
      <SignUp trackingUtm={trackingUtm} cpf={location.state?.cpf}/>
    </Layout>
  );
}

export default SignupPage;