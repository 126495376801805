import styled from "styled-components"
import InputMask from "react-input-mask"
import media from "styled-media-query"

export const Container = styled.div`
  max-width: 100%;
  max-height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    flex-direction: row;
  `}
`

export const Logo = styled.img`
  margin-left: 25px;
  src: url(${props => props.src});
  width: 36px;
  height: 35px;

  ${media.greaterThan("medium")`
    margin-left: 76px;
  `}
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    margin-left: 16px;
  `}

  ${media.greaterThan("medium")`
    margin-right: 17%;
  `}
`

export const Image = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.8;

  src: url(${props => props.src});
`

export const Description = styled.p`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.67px;
  color: ${props => props.theme.colors.charcoal};
`

export const Section = styled.section`
  display: flex;
  height: calc(100vh - 78px);
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 450px;
    margin: auto;
    justify-content: flex-start;
  `}
`

export const Profile = styled.div`
  margin-top: 50px;
  margin-left: 30px;
  width: 60px;
  height: 60px;

  ${media.lessThan("medium")`
    width: 60px;
    height: 60px;
    margin-top: 25px;
    margin-left: 20px;
  `}
`

export const User = styled.img`
  src: url(${props => props.src});
`

export const Form = styled.div`
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`

export const Label = styled.div`
  margin-top: 12px;
  margin-left: 30px;
  margin-right: 30px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.lessThan("medium")`
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: 61%;
  `}
`

export const Input = styled(InputMask)`
  padding-left: 15px;
  width: 100%;
  height: 48px;
  margin-top: 25px;
  border-radius: 10px;
  border: solid 1px ${props=> props.error? 'red': 'rgba(44, 45, 44, 0.3)'};
  background-color: #ffffff;

  ${media.lessThan("medium")`
    margin-top: 10px;
  `}
`

export const Button = styled.button`
  flex: 1;
  height: 48px;
  border-radius: 10px;
  border-style: outset;
  border: none;
  background-color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  margin-bottom: 10px;

  &:disabled {
    background-color: ${props => props.theme.colors.greenLighter};
    opacity: 0.6;
  }

  &:hover {
    background-color: ${props => props.theme.colors.greenDark};
  }
`

export const BackButton = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  cursor: pointer;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 0px 30px;
  align-items: center;
`;

export const TermText = styled.span`
  font-size: 13px;
  font-family: Poppins;
  color: ${({ theme }) => theme.colors.charcoal};
`;

export const TermTextBlue = styled.span`
  font-size: 13px;
  font-family: Poppins;
  color: #1E85F6;
  cursor: pointer;
`;

export const Circle = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  border: 1px solid #2C2D2C;
  border-radius: 15px;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  background-color: ${props => !props.selected? 'white': '#434343'};
`;

export const HelperText = styled.span`
  text-align: center;
  font-size: 10px;
  font-family: Poppins;
  color: ${({ theme }) => theme.colors.red};
`;