import React, { useState, useEffect } from "react"
import Photo from "../../images/consultor.png"
import AlertIcon from "../../images/fill-1.png"
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';
import HeaderProgress from "../../components/HeaderProgress"
// GraphQL
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import { navigate } from "gatsby"
import CheckIcon from '../../images/checkWhite.svg'

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Row,
  TermText,
  TermTextBlue,
  Circle,
  HelperText
} from "./styles"

import Alert from "../../components/alert"
import { Body, Buttons } from "../../steps/shared-styles"

import { formatNumber, extractNumber } from "../../utils";

const SignUp = ({ trackingUtm, cpf }) => {
  const [error, setError] = useState(null)
  const [tracking, setTracking] = useState({})
  const [selected, setSelected] = useState(false)

  useEffect(()=>{
    if(typeof window !== "undefined"){
      const trackingResult = localStorage.getItem('tracking');
      // if(trackingUtm?.origin){
      //   setTracking(trackingUtm);
      // } else 
      if(trackingResult){
        setTracking(JSON.parse(trackingResult));
      } 
    }
  }, []);


  const onSubmit = async (data) => {
    try {
      setError(null)
      const { name, cpf, password, phone } = data;
      const formattedNumber = formatNumber(phone)
      const { phone_number } = extractNumber(formattedNumber)

      const { createAccountPassword } = await client.request(mutations.CREATE_ACCOUNT_PASSWORD, {
        input: {
          name,
          document: {
            document_type: 'CPF',
            document_number: cpf,
          },
          phone_number,
          email: data.email,
          origin: 'LP - Lider',
          password,
          is_mobile: false,
          ...trackingUtm
        },
      });

      const { token } = createAccountPassword;

      client.setHeader("authorization", `Bearer ${token}`)

      navigate('/success')
    } catch ({ response }) {
      setError(response.errors[0]
        ? response.errors[0].message
        : "ocorreu um erro inesperado");
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues: {
      ...initialValues,
      cpf: cpf ? cpf : '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container padding>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>E aí? Se interessou pela Veriza? Faça seu pré cadastro e aproveite as melhores taxas!</p></Label>
            </Header>
            <Form>
            <Input
                placeholder="Digite o seu nome"
                onChange={({ target }) => formik.setFieldValue('name', target.value)}
                value={formik.values.name}
                error={formik.errors.name}
              />
              {formik.errors.name && <HelperText>{formik.errors.name}</HelperText>}

              <Input
                placeholder="(DD) 99999-9999"
                onChange={({ target }) => formik.setFieldValue('phone', target.value)}
                mask="(99) 99999-9999"
                maskChar=""
                type="tel"
                value={formik.values.phone}
                error={formik.errors.phone}
              />
              {formik.errors.phone && <HelperText>{formik.errors.phone}</HelperText>}

              <Input
                placeholder="***.***.***-**"
                onChange={({ target }) => {}}
                mask="999.999.999-99"
                maskChar=""
                value={formik.values.cpf}
                error={formik.errors.cpf}
              />
              {formik.errors.cpf && <HelperText>{formik.errors.cpf}</HelperText>}

              <Input
                placeholder="Crie uma senha"
                onChange={({ target }) => formik.setFieldValue('password', target.value)}
                value={formik.values.password}
                type='password'
                error={formik.errors.password}
              />
              {formik.errors.password && <HelperText>{formik.errors.password}</HelperText>}

              <Input
                placeholder="Confime sua senha"
                onChange={({ target }) => formik.setFieldValue('passwordConfirm', target.value)}
                value={formik.values.passwordConfirm}
                type='password'
                error={formik.errors.passwordConfirm}
              />
              {formik.errors.passwordConfirm && <HelperText>{formik.errors.passwordConfirm}</HelperText>}

            </Form>
            {error && <Alert icon={AlertIcon} description={error}></Alert>}

            <Row>
              <Circle selected={selected} onClick={() => setSelected(!selected)}>
                <img src={CheckIcon} />
              </Circle>
              <div style={{display: 'flex', flex: 1}}>
                <TermText>Li e concordo com os <TermTextBlue onClick={()=>navigate('/terms')}>Termos de Uso</TermTextBlue> e <TermTextBlue onClick={()=>navigate('/privacy')}>Política de Privacidade.</TermTextBlue></TermText>
              </div>
            </Row>
            <Buttons>
              <Button 
                onClick={formik.submitForm} 
                disabled={
                  formik.values.phone.length < 15 || formik.values.name.length < 4 || !selected
                } 
              >
                Tenho interesse!
              </Button>
            </Buttons>
          </Body>
        </Section>
      </Container>
    </div>
  )
}

export default SignUp
